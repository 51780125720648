.etheryo__title {
    background-size: cover;
    background-position-y: 100%;
    height: 80rem;
}

.etheryo__title-container {
    margin: 14rem 2rem;
    padding: 0 2rem;
    padding-right: 30rem;
}

.etheryo__title-container h1 {
    font-family: var(--font-title);
    font-size: 4rem;
    font-weight:500;
    text-align: left;
    color: white;
}

.etheryo__title-container span {
    font-family: var(--font-family);
    font-size: 1.5rem;
    font-weight:300;
    text-align: left;
    color: white;
}