.etheryo__navbar {
    display: flex;
    align-items: center;
    padding: 0 var(--navbar-margin);
    -webkit-backdrop-filter: var(--blur-effect);
    backdrop-filter: var(--blur-effect);
    position: fixed;
    top: 0; 
    width: 100%;
    height: var(--navbar-height);
    transition: all 0.3s ease 0s;
}

.etheryo__navbar_link {
    font-family: var(--font-family);
    position:fixed;
    right: var(--navbar-margin);
}

.etheryo__navbar_link li {
    padding: 1rem 0;
    display: inline-block;
    transition: all 0.3s ease 0s;
    border-radius: 4px;
}

.etheryo__navbar_link a:hover {
    background-color: var(--hover-navbar-color);
}

.etheryo__navbar_link a, button {
    padding: 1rem 1.5rem;
    color: var(--navbar-link-color);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0.25rem;
}

.etheryo__navbar_logo img {
    width: 2.25rem;
    height: auto;
    margin-right: var(--navbar-margin);
}

.etheryo__navbar_dropdown-menu {
    float: none;
    position: absolute;
    display: none;
    flex-direction: column;     /* stack flex items vertically */
    justify-content: center;    /* center items vertically, in this case */
    align-items: center;        /* center items horizontally, in this case */
    width: auto;
    background-color: white;
    border-radius: 0.25rem;
    padding: 0.25rem 0.25rem;
    box-shadow: 0px 8px 16px rgba(175, 16, 162, 0.15);
}

.etheryo__navbar_dropdown-menu a {
    color: black;
    position: relative;
    display: flex;
    border-radius: 0.25rem;
    flex-flow: nowrap;
    align-items: center;
    padding: 1rem 1rem;
    white-space: nowrap;
    word-wrap: break-word;
}

.etheryo__navbar_dropdown-menu a:hover {
    background-color: rgba(156, 39, 176, 0.15);
}

.etheryo__navbar_dropdown-menu svg {
    margin: 0 0.5rem;           /*to spaceup the Icon with the text inside the dropdown*/
}

.slide-bottom {
	-webkit-animation: slide-bottom 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(1.5rem);
              transform: translateY(1.5rem);
              opacity: 100;
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(1.5rem);
              transform: translateY(1.5rem);
              opacity: 100;
    }
  }
  
  .slide-top {
	-webkit-animation: slide-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(1.5rem);
              transform: translateY(1.5rem);
              opacity: 100;
    }
    100% {
      -webkit-transform: translateY(-1.5rem);
              transform: translateY(-1.5rem);
              opacity: 0;
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(1.5rem);
              transform: translateY(1.5rem);
              opacity: 100;
    }
    100% {
      -webkit-transform: translateY(-1.5rem);
              transform: translateY(-1.5rem);
              opacity: 0;
    }
  }
  