@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --font-family: 'Montserrat', sans-serif;
  --font-title: 'Rubik', sans-serif;
  --hover-navbar-color: rgba(255, 255, 255, 0.15);
  --navbar-height: 5rem;
  --navbar-margin: 5rem;
  --navbar-link-color: white;
  --blur-effect: blur(16px);
}

.etheryo__navbar_notfixed {
  padding: calc(var(--navbar-height)/2);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(33,11,52,0.7) 100%);
}